import { Component, Input } from '@angular/core';

@Component({
    template: ``,
    standalone: false
})
export class DashboardContainer {
  @Input() item;
 
}
