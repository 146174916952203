import { Directive, ViewContainerRef, Input } from '@angular/core';

@Directive({
    selector: '[appDashboardOutlet]',
    standalone: false
})
export class DashboardOutletDirective {
  @Input() item; 

  constructor(public viewContainerRef: ViewContainerRef) {
    
  }
}
