import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { DashboardAsistencialDTO } from '@src/app/models/Dashboard/dashboard-asistencial/DashboardAsistencialDTO';
import { SignosVitalesDTO } from '@src/app/models/Dashboard/dashboard-asistencial/SignosVitalesDTO';
import { LCaracteristicas } from '@src/app/models/SaludPlus/LCaracteristicas.model';
import { AuthenticationService } from '@src/app/services/Security/authentication.service';
import { DashboardAsistencialService } from '../services/dashboard-asistencial.service';
import { TimerService } from '@src/app/services/Generals/splus.timer.service';

@Component({
    selector: 'dashboard-asistencial',
    templateUrl: './dashboard-asistencial.component.html',
    styleUrls: ['./dashboard-asistencial.component.scss'],
    standalone: false
})
export class DashboardAsistencialComponent implements OnInit {
  data: DashboardAsistencialDTO[] = [];
  dataCamasDisponibles: DashboardAsistencialDTO[] = [];
  dataServicio: any[] = [];
  dataHabitacion: any[] = [];
  dataHabitacionFilter: any[] = [];
  isLoading = false;
  errorData = false;
  tituloPopUp = ""
  public isDisabledHabitacion = true;
  public selectedValueServicio = null;
  public selectedValueHabitacion = null;
  public selectedValueAdmision = "Todas";
  public pacienteFilter = '';
  caracteristicasAsistencial: LCaracteristicas[] = [];

  @Input() ShowFilters: any = false
  @Input() isEditable = false;


  public defaultItemServicio: { nombreServicio: string; idServicio: number } = {
    nombreServicio: "Todos...",
    idServicio: null,
  };


  public defaultItemHabitacion: { nombreHabitacion: string; idHabitacion: number } = {
    nombreHabitacion: "Todas...",
    idHabitacion: null,
  };
  signosVitales: SignosVitalesDTO[];
  idInterval;
  windowOpened = false;
  windowUrl = "";
  constructor( 
    private readonly timerService: TimerService,
    private authenticationService: AuthenticationService,
    private dashboardAsistencialService: DashboardAsistencialService) {

    this.getMenu();
  }
  ngOnInit() {
    
    this.isLoading = true;
    this.Init()

    this.timerService.startSubscription('tarjeta-asistencial', 60000, () => {
      this.Init();
    });

    //const ps = new PerfectScrollbar('#container');
    //const ps2 = new PerfectScrollbar('#container-disponibles');
  }

  ngOnDestroy(): void {  
    this.timerService.stopSubscription('tarjeta-asistencial');
  }

  OpenWin(url: string, nombre: string,) { 
    this.windowUrl = url;
    this.windowOpened = true;
    this.tituloPopUp = nombre;
  }

  IrCaracteristica(path: string): void {
    console.log("IrCaracteristica", path)
  }

  trackByFn(index, item) {
    return item.idCama;
  }


  async getMenu() {
    // Caracteristicas del menu principal
    this.caracteristicasAsistencial = (await this.authenticationService.ListadoCaracteristicasPorUsuario(true)) as LCaracteristicas[];
    this.caracteristicasAsistencial = this.caracteristicasAsistencial.filter(x => x.fkRecurso == 4 && x.caracteristicas == null);

    //console.log(this.caracteristicasAsistencial)
  }




  Init() {

    this.dashboardAsistencialService.ObtenerDashBoard<DashboardAsistencialDTO[]>(undefined, undefined).subscribe({
      next: (res) => {
        if (res.isSuccessful) {
          //console.log("res.result => ", res.result);

          res.result.forEach(element => {


            if (element.signosVitales) {
              try {
                let lsSignosVitalesDTO: SignosVitalesDTO[] = [];
                let singData = JSON.parse(element.signosVitales);

                var result = singData.data.map(o => {
                  var k = Object.keys(o)[0];
                  return {
                    vitalsign: k,
                    values: o[k] ? o[k].split(",").map(element => element.trim()) : []
                  };
                });

                lsSignosVitalesDTO = result;

                //  console.log("result => ", lsSignosVitalesDTO);

                element.signosVitales = lsSignosVitalesDTO;

              } catch (error) {
              }

            }


            //servicio
            if (!this.dataServicio.some(r => r.idServicio === element.idServicio)) {
              this.dataServicio.push({ idServicio: element.idServicio, nombreServicio: element.nombreServicio });
            }

            if (!this.dataHabitacion.some(r => r.idHabitacion === element.idHabitacion)) {
              this.dataHabitacion.push({ idServicio: element.idServicio, idHabitacion: element.idHabitacion, nombreHabitacion: element.nombreHabitacion });
            }



          });
          //  console.log("element.signosVitales => ", res.result);
          
          this.data = res.result.filter(s=>s.idAdmision);
          this.dataCamasDisponibles = res.result.filter(s=>!s.idAdmision);

          this.isLoading = false;
          //   console.log("this.data => ", this.data);
        } else {
          console.error("res.err => ", res);
          this.isLoading = false;
          this.errorData = true;
        }
      },
      error: (err) => {
        console.error(err);
        this.errorData = true;
        this.isLoading = false;
      },
    });

  }

  Maximizer() {

  }

  handleServicioChange(value) {
    this.selectedValueServicio = value;
    this.selectedValueHabitacion = undefined;


    if (value === undefined) {
      this.isDisabledHabitacion = true;
      this.dataHabitacionFilter = [];
    } else {
      this.isDisabledHabitacion = false;


      this.dataHabitacionFilter = this.dataHabitacion.filter(
        (s) => s.idServicio === value
      );
    }

  }


  filtroCamas(valor?: any) {
    this.selectedValueAdmision = valor;
    //console.log("valor variable es: ", this.selectedValueAdmision);    
  }

  public getContextData = (anchor: Element) => {
    return this.signosVitales
  }

  VerSignosVitales(signosVitales: SignosVitalesDTO[]) {
    this.signosVitales = signosVitales;
    //console.log("signosVitales => ", signosVitales);
    this.opened = true;
  }
  public close(status: string): void {
    // console.log(`Dialog result: ${status}`);
    this.opened = false;
  }
  opened = false;


  public closeWindow(status: string): void {
    // console.log(`Dialog result: ${status}`);
    this.windowOpened = false;
  }

}
