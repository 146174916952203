import { Pipe, PipeTransform } from '@angular/core';
import { DashboardAsistencialDTO } from '@src/app/models/Dashboard/dashboard-asistencial/DashboardAsistencialDTO';

@Pipe({
    name: 'servicioFilter',
    standalone: false
})
export class DataFilterPipe implements PipeTransform {

  transform(value: any, servicio?: number, habitacion?: number, admision?: any, paciente?:any): DashboardAsistencialDTO[] {
    
    let result = value;   
    
    if (servicio) {
      result = value.filter((item: { idServicio: number; }) => item.idServicio === servicio);    
    }

    if (habitacion) {
      result = result.filter((item: { idHabitacion: number; }) => item.idHabitacion === habitacion);    
    }

    if (!admision) {  
      result = result.filter((item: { idAdmision: number; }) => item.idAdmision ===  null);
    } 
    if (paciente) {
    
      result = result.filter((item: { nombrePaciente: string; documentoPaciente: string;   }) => {
        return item.nombrePaciente && (item.nombrePaciente.toLowerCase().includes(paciente.toLowerCase()) || item.documentoPaciente.toLowerCase().includes(paciente.toLowerCase()));
      });
    }
 
     
    return result;
  }

}
