import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'signosVitales',
    standalone: false
})
export class SignosVitalesPipe implements PipeTransform {

  transform(value: any, signo?: any, index?: number,): any {

    let data: any[] = [];

    let dataFilter = value.find((item: { vitalsign: any; }) => item.vitalsign === signo);

    if (dataFilter) {

      data = dataFilter.values;
      if (signo == 'TA') {

        data = data.map(element => {

          let ta = element.split('|');
          if (ta && ta.length > 0 && ta[index]) {
            element = ta[index]
          }

          return element;

        });
      }
    } else {
      if (signo == 'consolidado') {

        let valuesFC = value.find((item: { vitalsign: any; }) => item.vitalsign === "FC").values;
        let fc = valuesFC[valuesFC?.length - 1];
      
        let valuesFR = value.find((item: { vitalsign: any; }) => item.vitalsign === "FR").values;
        let fr = valuesFR[valuesFR?.length - 1]; 

          
        let valuesT = value.find((item: { vitalsign: any; }) => item.vitalsign === "T").values;
        let t = valuesT[valuesT?.length - 1]; 

        let valuesTA = value.find((item: { vitalsign: any; }) => item.vitalsign === "TA").values;
        let ta = valuesTA[valuesTA?.length - 1]; 
        if(ta != '--|--'){
           
          ta = ta.split('|')[0] + '.' + ta.split('|')[1];
        }
        

      let valuesSO2 = value.find((item: { vitalsign: any; }) => item.vitalsign === "SO2").values;
        let so2 = valuesSO2[valuesSO2?.length - 1]; 

        data = [
          { name: "Frecencia\nCardiaca", values: fc == '--' ? null : fc },
          { name: "Frecencia\nRespiratoria", values: fr == '--' ? null : fr },
          { name: "Temperatura", values: t == '--' ? null : t },
          { name: "Presión\nArterial", values: ta == '--|--' ? null : ta },
          { name: "Saturacion\nOxigeno", values: so2 == '--' ? null : so2 }
        ];
       // console.log("data => ", data);
         
      }
    }



    return data;
  }
}

@Pipe({
    name: 'signosVitalesCategoria',
    standalone: false
})
export class SignosVitalesCategoriaPipe implements PipeTransform {

  transform(value: any, signo?: any): any {
    let signoFilter = value.find((item: { vitalsign: any; }) => item.vitalsign === signo).values;

    if (signoFilter && signoFilter.length == 1 && (signoFilter[0] == '--' || signoFilter[0] == '--|--')) {
      return [];
    }
    return Array.from({ length: signoFilter.length }, (_, i) => i + 1)
  }

}
